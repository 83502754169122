import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const SecondPage = () => (
  <Layout>
    <h2>Success</h2>
    <p>We will be in touch!</p>
    <Link to="/">Back</Link>
  </Layout>
)

export default SecondPage
